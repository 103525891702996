import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Tags } from '@tryghost/helpers-gatsby'
import ShareIcon from '@material-ui/icons/Share';
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { tagRouteBaseUrl } from '../constants/strings'
import Heading from '../components/common/ui/Heading'
import Text from '../components/common/ui/Text'
import styled from 'styled-components'
import { deviceLessThan } from '../styles/breakpoints';
import Posts from '../components/BlogIndex/Posts'
import CustomDialog from '../components/common/ui/CustomDialog'
import { getOptimizedImageUrl } from '../utils/helpers'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import CustomLink from '../components/common/ui/CustomLink'
import { twitterHandle } from '../constants/strings'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/

const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin: 40px 0;
  opacity: 0.5;
  background-color: #d8d8d8;
`;

const Wrapper = styled.div`
    margin: 50px 0 0 0;
    @media ${deviceLessThan.tablet} {
        margin: 20px 0 0 0;
    }
`;

const ShareWrapper = styled.div`
    display:flex;
    align-items: baseline;
`;

const SocialMediaLinkWrapper = styled.div`
    padding: 10px;

    & > button ,a {
        margin: 0 10px 0 10px;
    }
`;

const Post = ({ data, location }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const post = data.ghostPost;
    const latestPost = data.allLatestPost.edges;
    //// random num from interval; min and max included
    let randomNum = Math.floor(Math.random() * (20 - 3 + 1) + 3);

    let randomPosts = latestPost.slice(randomNum - 3, randomNum);

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <Wrapper />
                    {post.tags && <div className="post-card-tags">
                        <Tags permalink={`${tagRouteBaseUrl}/:slug`} post={post} visibility="public" autolink={false} />
                    </div>}
                    <Heading
                        color='MEDIUM_BLACK'
                        margin='21px 0 25px 0'
                        sMargin='15px 0 20px 0'
                        lineHeight='1.44'
                        size='sm'
                        weight='normal'
                    >{post.title}</Heading>
                    <ShareWrapper>
                        <Text
                            color='MEDIUM_GREY'
                            size='md'
                            margin='0 0 34px 0'
                            sMargin='0 0 30px 0'
                            weight='medium'
                        >{post.published_at_pretty}
                        </Text>
                        <Text
                            margin='0 10px 0 65px'
                            sMargin='0 7px 0 48px'
                            color='MEDIUM_GREY'
                            weight='medium'
                            size='md'>Share</Text><ShareIcon style={{ cursor: 'pointer' }} onClick={handleClickOpen} />
                        <CustomDialog open={open} onClose={handleClose}>
                            <SocialMediaLinkWrapper>
                                <FacebookShareButton
                                    url={`${process.env.BASE_URI}/${post.slug}`}
                                    quote={post.title}
                                    hashtag="#Chqbook"
                                >
                                    <FacebookIcon size={36} round={true} />
                                </FacebookShareButton>
                                <CustomLink
                                    target='_blank'
                                    to={`https://www.linkedin.com/sharing/share-offsite/?url=${`${process.env.BASE_URI}/${post.slug}`}`}
                                >
                                    <LinkedinIcon size={36} round={true} />
                                </CustomLink>
                                <TwitterShareButton
                                    url={`${process.env.BASE_URI}/${post.slug}`} title={post.title} via={twitterHandle}
                                >
                                    <TwitterIcon size={36} round={true} />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={`${process.env.BASE_URI}/${post.slug}`}
                                    quote={post.title}
                                >
                                    <WhatsappIcon size={36} round={true} />
                                </WhatsappShareButton>
                            </SocialMediaLinkWrapper>
                        </CustomDialog>
                    </ShareWrapper>
                    <article className="content">
                        {post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={getOptimizedImageUrl(post.feature_image)} alt={post.title} />
                            </figure> : null}
                        <section className="post-full-content">
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <Divider />
                    <Heading size='xmd' color='MEDIUM_BLACK'>Read More</Heading>
                </div>
                <Posts posts={randomPosts} pageContext={{}} indexRoute='/blog' />
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }

    allLatestPost: allGhostPost(
        limit: 20,
        filter:{
            slug:{ne: $slug}
            tags: {elemMatch: {slug: {ne: "opinion"}}}
        }
  	    sort: { order: DESC, fields: [published_at] }
        ){
         edges{
            node{
                ...GhostPostFields
            }
        }
    }
}
`
