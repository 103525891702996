import React from 'react'
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { deviceLessThan } from '../../../styles/breakpoints';
import PropTypes from 'prop-types'


export const Styledialogue = styled(props => <Dialog {...props} />)`
`;
const CustomDialog = props => {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            {props.children}
        </Dialog>
    )
}

CustomDialog.propTypes = {

}

export default CustomDialog

